import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [0,3,15,17,18,19,4,5,8,6,7,9,11,13];

export const dictionary = {
		"/(landing)": [~50,[14]],
		"/(app)/about-us": [20,[2]],
		"/(app)/admin": [21,[2,3]],
		"/(app)/admin/bulkImport": [~22,[2,3]],
		"/(app)/admin/orders": [~23,[2,3]],
		"/(landing)/blog": [51,[14,15]],
		"/(landing)/blog/(posts)/how-to-create-a-childrens-book-using-generative-ai": [52,[14,15,16,17]],
		"/(landing)/blog/(posts)/how-to-write-a-childrens-book": [53,[14,15,16,18]],
		"/(landing)/blog/(posts)/making-meaning-personalized-stories-ai": [54,[14,15,16,19]],
		"/(app)/build-a-book": [24,[2]],
		"/(app)/character": [26,[2,4]],
		"/(app)/character/create": [~27,[2,4]],
		"/(app)/c/[slug]": [~25,[2]],
		"/(app)/design": [28,[2,5]],
		"/(app)/design/create": [30,[2,5]],
		"/(app)/design/p/[slug]": [31,[2,5,8]],
		"/(app)/design/p/[slug]/edit": [~32,[2,5,8]],
		"/(app)/design/p/[slug]/print": [33,[2,5,8]],
		"/(app)/design/[slug]/edit": [~29,[2,5,6,7]],
		"/(app)/idea-generator": [35,[2,9]],
		"/(app)/idea/[uuid]": [~34,[2]],
		"/(app)/login": [~36,[2]],
		"/(app)/payments/success": [37,[2]],
		"/(app)/policies/cookies": [38,[2,10]],
		"/(app)/policies/privacy": [39,[2,10]],
		"/(app)/policies/terms": [40,[2,10]],
		"/(app)/pricing": [41,[2]],
		"/(app)/project/[slug]": [42,[2]],
		"/(app)/referrals": [~43,[2]],
		"/(app)/settings": [44,[2,11]],
		"/(app)/settings/profile": [45,[2,11]],
		"/(app)/share/[uuid]": [46,[2,12,13]],
		"/(app)/subscribe": [47,[2]],
		"/(app)/user": [48,[2]],
		"/(app)/welcome": [~49,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';